<template>
	<div class="progress-bar" :class="[empty && 'empty']" id="progress">
		<div class="shell">
			<div
				class="bar"
				:style="{
					width: currentWidth
				}"
			>
				<span v-if="!empty && current > 0">{{
					current.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
				}}</span>
			</div>
		</div>
		<div
			v-if="!empty"
			style="display: flex; flex-direction: row; justify-content: space-between; width: 100%"
		>
			<p style="flex: 1">
				{{
					min.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') ||
						current.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
				}}
			</p>
			<p style="flex: 1; text-align: right">
				{{ max.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }}
			</p>
		</div>
	</div>
</template>

<script>
export default {
	name: 'progress-bar',
	props: {
		current: { type: Number },
		max: { type: Number },
		min: { type: Number },
		empty: { type: Boolean }
	},
	computed: {
		currentWidth() {
			if (!this.current || this.current === 0) {
				return '0%'
			}
			if (this.current > 0) {
				return (
					Math.max(Math.round((this.current / this.max) * 100), 12) +
					'%'
				)
			}
		}
	}
}
</script>

<style lang="scss">
@import '../../styles/variables';

.progress-bar {
	border-radius: 5px;
	width: 100%;
	padding: 5px;
	h4 {
		color: $roundup-navy;
		margin: 0px;
		margin-bottom: 5px;
	}
	.shell {
		height: 48px;
		width: 100%;
		border: 1px solid $roundup-light-slate;
		border-radius: 24px;
		background: $roundup-bone;
		padding: 3px;
	}
	&.empty .bar {
		background: $roundup-light-grey;
	}
	.bar {
		background: $roundup-mint;
		height: 40px;
		width: 15px;
		border-radius: 24px;
		span {
			float: right;
			height: 100%;
			display: flex;
			align-items: center;
			padding: 4px 5px;
			color: $roundup-charcoal;
			font-size: 1em;
		}
	}
}
</style>
