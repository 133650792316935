<template>
	<div class="overview" id="overview">
		<div
			style="padding: 12px; padding-left: 16px; overflow: auto !important; flex-wrap: nowrap !important; display: flex; flex-direction: row; position: absolute; z-index: 50;"
		>
			<to-do
				class="task"
				v-for="(task, i) in taskList"
				:key="i"
				:task="task"
				:disabled="true"
				:ref="`task${i}`"
				@dismissed="dismissedTasks = dismissedTasks + 1"
			>
			</to-do>
		</div>

		<v-container
			fluid
			:style="{
				marginTop: hasTodos ? `90px !important` : 'auto !important'
			}"
		>
			<v-card-title class=" ml-0 mb-0 pb-0"
				>Donations
				<router-link
					:to="{ name: 'reports.donations' }"
					class="ml-4 font-bold"
					>View Reports</router-link
				>
			</v-card-title>
			<v-row class="row mt-0">
				<v-col cols="6" style="minWidth: 400px !important">
					<v-card style="width: 100% !important" class="pb-4">
						<v-card-title class="pb-0">
							<h3>Total Donations</h3>
						</v-card-title>
						<v-row class="center pt-0">
							<h1
								style="font-size: 4em !important;"
								v-if="!roi.isLoading"
							>
								{{ currencyFormat(roi.total) }}
							</h1>
							<v-progress-circular
								class="mb-4"
								v-else
								indeterminate
								size="50"
								color="primary"
							></v-progress-circular>
						</v-row>
						<div
							class="alert alert-info mx-4"
							style="margin-top: 10px; text-align: center"
						>
							<strong>{{
								currencyFormat(roi.total - roi.fees)
							}}</strong>
							return on investment of
							{{ currencyFormat(roi.fees) }} in RoundUp App.
						</div>
					</v-card>
				</v-col>
				<v-spacer></v-spacer>
				<v-col cols="6" style="minWidth: 400px !important;">
					<v-card
						style="width: 100% !important; height: 100% !important;"
						class="pb-4"
					>
						<v-card-title>
							<h3>Average Donations Per Month</h3>
						</v-card-title>
						<v-row class="center pt-0">
							<h1
								style="font-size: 4em !important;"
								v-if="!roi.isLoading"
							>
								{{ currencyFormat(roi.avgPerMonth) }}
							</h1>
							<v-progress-circular
								class="mb-4"
								v-else
								indeterminate
								size="50"
								color="primary"
							></v-progress-circular>
						</v-row>
					</v-card>
				</v-col>
			</v-row>
		</v-container>
		<v-container fluid>
			<v-card-title class=" ml-0 mb-0 pb-0"
				>Donors
				<router-link :to="{ name: 'donors' }" class="ml-4 font-bold"
					>View Donors</router-link
				>
			</v-card-title>
			<v-row class="row mt-0">
				<v-col
					class="flex-grow-3 flex-shrink-3"
					style="min-width: 230px !important; max-width: 250px !important"
				>
					<v-card
						style="width: 100% !important;  height: 100% !important;"
						class="pb-4"
					>
						<v-card-title class="pb-0">
							<h3># Active Donors</h3>
						</v-card-title>
						<v-row class="center pt-0">
							<v-col
								cols="12"
								class="center"
								style="text-align: center"
								v-if="!roi.isLoading"
							>
								<h1 style="font-size: 4em !important;">
									{{ general.totalDonors }}
								</h1>
							</v-col>
							<v-progress-circular
								class="mb-4"
								v-else
								indeterminate
								size="50"
								color="primary"
							></v-progress-circular>
						</v-row>
						<v-row
							v-if="!roi.isLoading && general.totalDonors === 0"
						>
							<v-spacer></v-spacer>
							<Roundup-Button
								class="mx-auto "
								color="primary"
								@click="toggleCSVDialog"
								>Invite Donors</Roundup-Button
							>
							<v-spacer></v-spacer>
						</v-row>
					</v-card>
				</v-col>
				<v-col cols="6" style="minWidth: 300px !important">
					<v-card
						style="width: 100% !important; height: 100% !important;"
						class="pb-4"
					>
						<v-card-title class="pb-0">
							<h3>Donors By Type</h3>
						</v-card-title>
						<v-row class=" pt-0" v-if="!roi.isLoading">
							<v-col
								cols="4"
								class="center"
								style="text-align: center"
							>
								<h1 style="font-size: 4em !important;">
									{{ general.countRoundUpDonors }}
								</h1>
								<p>
									RoundUp
									<v-tooltip
										top
										max-width="200"
										color="#484A50"
										content-class="donorCountTooltip"
									>
										<template
											v-slot:activator="{ on, attrs }"
										>
											<i
												v-bind="attrs"
												v-on="on"
												class="fa-regular fa-circle-info"
											></i>
										</template>
										<span
											>Donors who are giving the change
											from their roundups each month</span
										>
									</v-tooltip>
								</p>
							</v-col>
							<v-col
								cols="4"
								class="center"
								style="text-align: center"
							>
								<h1 style="font-size: 4em !important;">
									{{ general.countMonthlyDonors }}
								</h1>
								<p>
									Monthly
									<v-tooltip
										top
										max-width="200"
										color="#484A50"
									>
										<template
											v-slot:activator="{ on, attrs }"
										>
											<i
												v-bind="attrs"
												v-on="on"
												class="fa-regular fa-circle-info"
											></i>
										</template>
										<span
											>Donors who are giving a fixed
											amount on a monthly basis</span
										>
									</v-tooltip>
								</p>
							</v-col>
							<v-col
								cols="4"
								class="center"
								style="text-align: center"
							>
								<h1 style="font-size: 4em !important;">
									{{ general.countOneTimeDonors }}
								</h1>
								<p>
									One-time
									<v-tooltip
										top
										max-width="200"
										color="#484A50"
									>
										<template
											v-slot:activator="{ on, attrs }"
										>
											<i
												v-bind="attrs"
												v-on="on"
												class="fa-regular fa-circle-info"
											></i>
										</template>
										<span
											>Donors who have only donated one
											time gifts.
										</span>
									</v-tooltip>
								</p>
							</v-col>
						</v-row>
						<v-row class="center" v-else>
							<v-progress-circular
								class="mb-4"
								indeterminate
								size="50"
								color="primary"
							></v-progress-circular>
						</v-row>
					</v-card>
				</v-col>
				<v-col class="flex-grow-1 flex-shrink-3">
					<v-card style="height: 100%" class="pb-4">
						<v-app-bar
							flat
							color="rgba(0, 0, 0, 0)"
							class="pl-0 ml-0"
						>
							<v-card-title class="pl-0 ml-0">
								<h3 class="ml-0">Donor Goal</h3>
							</v-card-title>

							<v-spacer></v-spacer>
							<v-btn
								icon
								@click="openDonorGoal"
								v-if="institution.donor_goal"
								style="font-size: 18px !important"
							>
								<i class="fa-regular fa-pen-to-square"></i>
							</v-btn>
						</v-app-bar>

						<div
							style="width: 90%; margin: auto"
							v-if="institution.donor_goal"
						>
							<progress-bar
								:current="general.totalDonors"
								:min="0"
								:max="
									Math.max(
										institution.donor_goal,
										general.totalDonors
									)
								"
							/>
						</div>
						<div class="progress-empty" v-else>
							<progress-bar :min="0" :max="1000" empty />
							<div
								style="display: flex; flex-direction: row; justify-content: center; align-items: center"
							>
								<Roundup-button
									style="margin: auto; margin-top: 10px !important"
									@click="showGoalEdit = !showGoalEdit"
									color="primary"
									>Set Donor Goal</Roundup-button
								>
							</div>
						</div>
					</v-card>
				</v-col>
			</v-row>
		</v-container>
		<v-dialog v-model="firstTimeModal" width="768" :attach="this.$el">
			<v-card class="first-time-modal">
				<v-toolbar
					flat
					color="rgba(0, 0, 0, 0)"
					width="100%"
					class="pl-2"
				>
					<v-card-title class="px-0">
						30 Day Launch Success Playbook
					</v-card-title>
					<v-btn
						small
						fab
						@click="firstTimeModal = false"
						elevation="0"
						plain
						><v-icon color="black">fas fa-times</v-icon></v-btn
					>
				</v-toolbar>
				<v-card-text>
					<div
						style="display: flex; flex-direction: row; justify-content: center"
						class="mb-6"
					>
						<div class="mr-3">
							<v-img
								src="@/assets/30-day-launch.png"
								max-width="343px"
							/>
							<p
								class="mt-3"
								style="color: #747A8D; font-size: 13px"
							>
								You can find this guide and many more resources
								in the Support section of the Marketing Library!
							</p>
						</div>

						<div class="ml-3">
							<p style="line-height: 1.5">
								Welcome to RoundUp App! We're committed to
								helping your nonprofit grow. Download the
								Playbook to get:
							</p>
							<ul class="mt-4">
								<li class="mb-2" style="line-height: 1.5">
									<strong>Pro tips</strong> for launching a
									successful roundup program
								</li>
								<li class="mb-2" style="line-height: 1.5">
									<strong>Helpful checklists</strong> for
									prelaunch, launch and continued growth
								</li>
								<li style="line-height: 1.5">
									<strong>RoundUp stats</strong> and client
									testimonials
								</li>
							</ul>
						</div>
					</div>
					<div
						style="display: flex; flex-direction: row; justify-content: center"
					>
						<Roundup-Button @click="downloadPDF" color="primary"
							>Download Playbook</Roundup-Button
						>
					</div>
				</v-card-text>
			</v-card>
		</v-dialog>
		<v-dialog v-model="showGoalEdit" width="500">
			<v-card>
				<v-card-title>
					Edit Your Goal
				</v-card-title>

				<v-card-text style="">
					<v-text-field
						type="number"
						outlined
						label="Donor Goal"
						@change="onChange"
						:value="goal"
						required
						style="width: 100%; margin: auto"
					></v-text-field>
				</v-card-text>

				<v-card-actions style="padding-bottom: 16px !important">
					<Roundup-Button color="clear" @click="showGoalEdit = false">
						Cancel
					</Roundup-Button>
					<v-spacer></v-spacer>

					<Roundup-Button
						color="primary"
						@click="submit"
						:disabled="!goal"
					>
						Save
					</Roundup-Button>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<CSVDialog ref="csvDialog" :orgName="orgName" />
	</div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import moment from 'moment'
import cloneDeep from 'lodash/cloneDeep'
import {
	numberFormat,
	getLastMonth,
	get,
	currencyFormat
} from '../../../utilities'
import table from '@/mixins/table'
import download from '@/mixins/download'
import UiLabel from '../../ui/Label'
import Screen from '../../ui/Screen'
import UiButton from '../../ui/Button'
import Chart from '../../ui/Chart'
import ProgressBar from '../../ui/ProgressBar'
import { ToDo, BarChart } from '../../ui'
import { analytics } from '@/utilities/tracker'
import CSVDialog from '../Donors/CSVDialog'
export default {
	name: 'overview',
	components: {
		Screen,
		UiButton,
		UiLabel,
		Chart,
		ProgressBar,
		ToDo,
		BarChart,
		CSVDialog
	},
	mixins: [table, download],
	data() {
		return {
			donatedYTD: '$15,345.21',
			pendingDeposit: '$455.32',
			nextDeposit: '08/31/2018',
			newDonors: 0,
			totalDonors: 124,
			year: new Date().getFullYear(),
			date: null,
			timeframe: 0,
			institution: cloneDeep(this.$store.state.institution.item),
			showGoalEdit: false,
			goal: cloneDeep(this.$store.state.institution.item.donor_goal),
			goalRule: [
				value => !/\D/g.test(value) || 'Please enter a whole number'
			],
			dismissedTasks: 0,
			firstTimeModal: false
		}
	},
	computed: {
		...mapGetters(['taskList', 'donorGoal', 'firstTimeLoggingIn']),
		...mapState({
			general: ({ reportList }) => reportList.general,
			authToken: ({ me }) => me.token,
			me: ({ me }) => me,
			roi: ({ reportList }) => reportList.roi,
			items: ({ reportList }) => reportList.items,
			total: ({ reportList }) => reportList.total,
			graph: ({ reportList }) => reportList.graph
		}),
		hasTodos() {
			return (
				this.taskList.length > 0 &&
				this.dismissedTasks < this.taskList.length
			)
		},
		orgName() {
			if (this.me) {
				return this.me.current.institution.name
			}
			return null
		},
		bgColors() {
			return ['#3770FE', '#1CD4E3']
		}
	},
	methods: {
		currencyFormat,
		...mapActions([
			'getDonationsReport',
			'getTasks',
			'getGeneralReport',
			'updateInstitution',
			'getCurrentUser',
			'getUsers',
			'getROIReport'
		]),
		numberFormat,
		getLastMonth,
		get,
		editGoal() {
			alert('edit goal')
		},
		onChange(v) {
			this.goal = parseInt(v)
		},
		openDonorGoal() {
			analytics.track('donor_goal_clicked', {
				institution_id: this.institution.id,
				goal: this.donorGoal
			})
			this.showGoalEdit = !this.showGoalEdit
		},
		reloadHome() {
			this.getCurrentUser()
			this.getTasks()
			this.getDonationsReport(this.getParameters())
			this.getGeneralReport()
			this.getROIReport()
			this.getUsers().then(() => this.getTasks())
		},
		getYears() {
			if (
				this.general.donatedYears &&
				this.general.donatedYears.length > 0
			) {
				return this.general.donatedYears
			}
			return [this.year]
		},
		paramsChanged(params) {
			this.getDonationsReport(params)
		},
		dateChange(dates) {
			let params = this.getParameters()
			if (dates) {
				params.startDate = moment(dates[0]).format('YYYY-MM-DD')
				params.endDate = moment(dates[1]).format('YYYY-MM-DD')
			}
			this.setParameters(params)
		},
		submit() {
			this.showGoalEdit = false
			analytics.track('donor_goal_updated', {
				institution_id: this.institution.id,
				goal: this.donorGoal
			})
			this.update()
		},
		downloadPDF() {
			analytics.track('30-day-launch-PDF-clicked')
			const url =
				'https://admin-marketing-library.s3.amazonaws.com/RoundUp%20App%20-%2030%20Day%20Launch.pdf.pdf'
			window.open(url, '_blank')
		},
		update() {
			this.isLoading = true
			try {
				if (!this.institution.donor_goal && parseInt(this.goal)) {
					analytics.track('donor_goal_set', {
						institution_id: this.institution.id,
						institution_name: this.institution.name,
						donor_goal: parseInt(this.goal)
					})
				} else if (this.institution.donor_goal && parseInt(this.goal)) {
					analytics.track('donor_goal_updated', {
						institution_id: this.institution.id,
						institution_name: this.institution.name,
						donor_goal: parseInt(this.goal)
					})
				}
				this.institution.donor_goal = parseInt(this.goal)
			} catch {}
			this.updateInstitution(this.institution)
				.then(() => {
					this.showGoalEdit = false
					this.getCurrentUser()
					this.getTasks()
				})
				.catch(err => {
					this.showGoalEdit = false

					const updateError =
						err.response.data && err.response.data.message
							? '- ' + err.response.data.message
							: ''

					this.setAlert({
						alertText: updateError,
						showAlert: true,
						alertColor: 'error'
					})
				})
		},
		toggleCSVDialog() {
			this.$refs.csvDialog.toggle()
		}
	},
	mounted() {
		this.reloadHome()
		this.$root.$on('switchedInstitution', this.reloadHome)
		if (this.firstTimeLoggingIn) {
			this.firstTimeModal = true
		}
	},
	watch: {
		timeframe: {
			handler(newValue) {
				const times = [3, 6, 12, 100]
				const startDate = moment(new Date()).subtract(
					times[newValue],
					'months'
				)
				this.dateChange([startDate, new Date()])
			}
		},
		firstTimeModal(newValue) {
			this.$store.commit('USER_SET_FIRST_TIME_LOGGING_IN', false)
		},
		$route(to, from) {
			if (to.query.goal) {
				this.showGoalEdit = true
			}
		}
	}
}
</script>

<style lang="scss">
@import '../../../styles/variables';

.overview {
	width: 100%;
	padding-left: 16px !important;
	padding-right: 16px !important;
}
.row {
	margin: 0px;
}
.v-card__title {
	color: $roundup-charcoal !important;
}
.tasklist {
	display: flex;
	flex-direction: row;
	margin-top: 10px;
	margin-left: 0px;
	overflow-x: scroll;
	width: 100%;
	max-width: 100%;
}
.progress-empty {
	width: 90%;
	margin: auto;
	align-items: center;
	justify-content: center;
	display: flex;
	flex-direction: column;
	padding-bottom: 25px;
}
.v-toolbar__content {
	button {
		color: $roundup-charcoal;
	}
}
.barLegend {
	height: 24px;
	width: 24px;
}
.sweepstakes-banner {
	background: $roundup-purple !important;
	color: white !important;
	p {
		color: inherit !important;
		text-align: center !important;
		margin-bottom: 0px !important;
	}
	border-radius: 8px !important;
	&:hover {
		cursor: pointer;
	}
}

.v-tooltip__content {
	opacity: 1 !important;
}

.v-dialog {
	.first-time-modal {
		.v-toolbar__content {
			justify-content: space-between;
		}
	}
}
</style>
